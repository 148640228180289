/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../shared/components/share-modal/share-modal.component.ngfactory";
import * as i5 from "../../shared/components/share-modal/share-modal.component";
import * as i6 from "../../shared/api/rentassistant-api.generated";
import * as i7 from "../../shared/api/rentassistant-api";
import * as i8 from "../../shared/components/house-header/house-header.component.ngfactory";
import * as i9 from "../../shared/components/house-header/house-header.component";
import * as i10 from "../../core/services/localized-router.service";
import * as i11 from "../profile/profile.component.ngfactory";
import * as i12 from "../profile/profile.component";
import * as i13 from "@ngx-translate/core";
import * as i14 from "./edit-profile.component";
import * as i15 from "@ng-bootstrap/ng-bootstrap";
import * as i16 from "../../core/services/profilewizard-state.service";
var styles_EditProfileComponent = [i0.styles];
var RenderType_EditProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditProfileComponent, data: {} });
export { RenderType_EditProfileComponent as RenderType_EditProfileComponent };
function View_EditProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "profile-wizard"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "hidden-on-mobile": 0 }), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "profile-wizard"; var currVal_1 = _ck(_v, 3, 0, _co.isDetail); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 5, 0); }, null); }
function View_EditProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-share-modal", [], null, [[null, "cancel"], [null, "closeModal"], [null, "confirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_0 && ad);
    } if (("closeModal" === en)) {
        var pd_1 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_1 && ad);
    } if (("confirm" === en)) {
        var pd_2 = (_co.onShareConfirm($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_ShareModalComponent_0, i4.RenderType_ShareModalComponent)), i1.ɵdid(1, 4308992, null, 0, i5.ShareModalComponent, [i6.RentAssistantClient, i7.RentAssistantApi], null, { closeModal: "closeModal", confirm: "confirm" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_EditProfileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { shareModal: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "profile-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "profile"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "hidden-on-mobile": 0, "center": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "scroll-container scroll-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ra-house-header", [], null, null, null, i8.View_HouseHeaderComponent_0, i8.RenderType_HouseHeaderComponent)), i1.ɵdid(8, 114688, null, 0, i9.HouseHeaderComponent, [i10.LocalizedRouter], { titleKey: [0, "titleKey"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "center-on-desktop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "center-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "ra-profile", [], null, [[null, "addRenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addRenter" === en)) {
        var pd_0 = (_co.addRenter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ProfileComponent_0, i11.RenderType_ProfileComponent)), i1.ɵdid(12, 114688, null, 0, i12.ProfileComponent, [i10.LocalizedRouter, i13.TranslateService, i3.ActivatedRoute], { profile: [0, "profile"], baseUrl: [1, "baseUrl"] }, { addRenter: "addRenter" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditProfileComponent_1)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["shareModal", 2]], null, 0, null, View_EditProfileComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "profile"; var currVal_1 = _ck(_v, 5, 0, !_co.isDetail, _co.isDetail); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = "Profile.Title"; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.profile; var currVal_4 = "/app/profiel/"; _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_5 = !_co.isDetail; _ck(_v, 14, 0, currVal_5); }, null); }
export function View_EditProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-edit-profile", [], null, null, null, View_EditProfileComponent_0, RenderType_EditProfileComponent)), i1.ɵdid(1, 245760, null, 0, i14.EditProfileComponent, [i3.Router, i3.ActivatedRoute, i10.LocalizedRouter, i15.NgbModal, i7.RentAssistantApi, i16.ProfileWizardStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditProfileComponentNgFactory = i1.ɵccf("ra-edit-profile", i14.EditProfileComponent, View_EditProfileComponent_Host_0, {}, {}, []);
export { EditProfileComponentNgFactory as EditProfileComponentNgFactory };
