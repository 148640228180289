<ra-house-header-background></ra-house-header-background>
<ra-menu
  [visible]="menuVisible"
  (visibleChange)="menuVisibleChange($event)"></ra-menu>
<div class="header">
  <div class="left-container">
    <div
      *ngIf="menuIconVisible"
      class="icon-container visible-until-desktop">
      <span class="menu" (click)="toggleMenu($event)">☰</span>
    </div>
    <div class="action-icon">
      <svg-icon
          *ngIf="backIconVisible"
          src="assets/images/chevron-left.svg"
          (click)="navigateHome()"
          [applyCss]="true"></svg-icon>
    </div>
        <div *ngIf="logoVisible">
      <div>
        <div class="logo">
          <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
        </div>
        <div class="name">clee</div>
      </div>
    </div>
  </div>
  <div class="content-container">
    <div class="left-container-2"></div>
    <div class="title">
      <h1>{{ titleKey | translate }}</h1>
      <h4>{{ subTitle }}</h4>
      <h4 *ngIf="extraInfo">{{ extraInfo }}</h4>
    </div>
    <div class="right-container">
      <ng-content></ng-content>
      <div class="select-button" *ngIf="candidacyFilter">
          <input type="checkbox" [(ngModel)]="showArchivedValue" (change)="candidacyFilterShowArchivedChanged()" id="showArchivedValue" value="showArchivedValue">
          <label  for="showArchivedValue">
            <i class="far fa-archive"></i>
            <span class="visible-from-desktop">
              <!-- {{ 'Candidacies.ShowArchived' | translate }} -->
            </span>
          </label>
      </div>
    </div>
  </div>
</div>
